
@for $i from 1 through 100 {
  .text-#{$i} {
    font-size: #{$i}px;
  }
  @include mq(sm,min){
    .pc-text-#{$i} {
      font-size: #{$i}px;
    }
  }
  @include mq(sm,max){
    .sp-text-#{$i} {
      font-size: #{$i}px;
    }
  }
}

.text{
  line-height: 1.6;
  &-center{
    text-align: center;
  }
}
@include mq(sm,max){
  .sp-text-center {
    text-align: center;
  }
}

@include mq(sm,min){
  .pc-text-center {
    text-align: center;
  }
}
