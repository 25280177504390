html,
body,
h1,
h2,
h3,
h4,
ul,
ol,
dl,
li,
dt,
dd,
p,
div,
span,
img,
a,
table,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  font-feature-settings:"palt";
}


article,
header,
footer,
aside,
figure,
figcaption,
nav,
section {
    display: block;
}

button{
  border: 0;
  font: unset;
  background-color: unset;
  padding: 0;
}


html {
  font-size: 62.5%;
  font-weight: 400;
  font-family: $fontFamiry;
  background-image: url('../img/bg.png');
}

_::-webkit-full-page-media, _:future, :root html{
  font-weight: 500;
}

body {
  // font-size: 1rem;
  // line-height: 1;
  font-size: 1.4rem;
  line-height: 1.75;

  &:first-line {
    line-height: 1;
  }

  position: initial;
  top: auto;
  width: 100vw;
  overflow-x: hidden;

  &[data-modalActive="true"]{ //modal.js
    position: fixed;
    z-index: 10;
  }
}

ol,
ul {
  list-style: none;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover{
    cursor: pointer;
  }
}

// img{
//   height: auto;
//   width: 100%;
// }

@mixin input {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  max-width: 100%;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

input[type="text"]{
  @include input;
}
input[type="email"]{
  @include input;
}

input[type="tel"]{
  @include input;
}
input[type="checkbox"]{
  @include input;
}
textarea{
  @include input;
}
select{
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box;

  &::-ms-expand {
    display: none;
  }
}
